<template>
  <header class="home-header">
    <div class="home-header__inner">
      <div class="home-header__inner__left">
        <img alt="Vue logo" src="../assets/logo.png">
      </div>
      <div class="home-header__inner__right">
        <h1 id="main-title">云雾草庄</h1>
        <h2 class="tagline">
          {{tag1}}
          <br>
          {{tag2}}
        </h2>
      </div>
    </div>
  </header>
  <section class="section-news">
    <Bulletin />
  </section>
  <section class="section-concept">
    <Concept />
  </section>
  <section class="section-platform">
    <Platform />
  </section>
</template>

<script>
// @ is an alias to /src
import Bulletin from '@/components/Bulletin.vue'
import Concept from '@/components/Concept.vue'
import Platform from '@/components/Platform.vue'

export default {
  name: 'Home',
  data () {
    return {
      tag1: '实践道德精神',
      tag2: '弘扬国学根文化'
    }
  },
  components: {
    Bulletin,
    Concept,
    Platform
  }
}
</script>

<style lang="scss" scoped>
.home-header {
  padding: .4rem .4rem .3rem;
  &__inner {
    max-width: 12.6rem;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &__left {
      width: 39%;
      text-align: right;
      // vertical-align: middle;
      img {
        width: 2rem;
        // height: 2.15rem;
        padding-top: .4rem;
        margin-right: .6rem;
      }
    }
    &__right {
      width: 61%;
      h1 {
        display: none;
      }
      .tagline {
        font-size: .51rem;
        margin: 0;
        padding: 0;
        border-bottom: 0;
        font-weight: 300;
        line-height: 1.45;
      }
    }
  }
}
.section-news {
  text-align: center;
  padding: .25rem 2.3rem;
}
.section-concept {
  // text-align: center;
  padding: .25rem .4rem;
  background-color: #f6f6f6;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-platform {
  padding: .25rem .4rem .45rem;
  text-align: center;
}
@media screen and (max-width: 600px) {
.home-header {
  &__inner {
    flex-direction: column;
    text-align: center;
    &__left {
      width: 100%;
      text-align: center;
      img {
        margin-right: 0;
      }
    }
    &__right {
      width: 100%;
      .tagline {
        font-size: .32rem;
      }
    }
  }
}
.section-news {
  padding: .25rem .15rem;
}
}
</style>
