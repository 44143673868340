<template>
    <h3>公告</h3>
    <div class="bulletin">
      <p class="bulletin__content" v-for="(item, index) in dataList[0]" :key="index">{{item}}</p>
    </div>
</template>

<script>
export default {
  name: 'Bulletin',
  mounted () {

  },
  setup () {
    const dataList = [['欢迎周六（3月19日）晚7:30? YY语音频道:20159376，和厚音老师一起交流国学名著《庄子·外篇》。',
      '请于周五下午前将你遇见各类疑问发表在www.xuande99.com 答疑栏目中以便交流。']]
    return { dataList }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: .21rem;
  font-weight: 600;
  line-height: 1.45;
}
.bulletin {
  background: #B0C4DE;
  border-radius: .05rem;
  padding: .1rem .4rem;
  // width: auto;
  &__content {
    text-align: justify;
  }
}
@media screen and (max-width: 600px) {

}
</style>
